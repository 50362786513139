import LanguageDetector from 'i18next-browser-languagedetector';
import { use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { locales, localesKeys } from './i18n-locales';
import { LS_LANG_KEY } from './consts';

const languageDetector = new LanguageDetector(null, {
  order: ['localStorage', 'navigator'],
  lookupLocalStorage: LS_LANG_KEY,
})

const i18nInstance = use(languageDetector).use(initReactI18next);

i18nInstance.init({
  resources: locales,
  supportedLngs: localesKeys,
  fallbackLng: localesKeys,
});

i18nInstance.on('languageChanged', (lng) => (document.documentElement.lang = lng))