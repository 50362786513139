import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@/shared/ui/icon';
import { LS_LANG_KEY } from '@/shared/i18n/consts';
import { localesKeys } from '@/shared/i18n/i18n-locales';
import styles from './styles.module.scss';

export const LangPicker = () => {
  const { i18n } = useTranslation();
  const [isOpened, setIsOpened] = useState(false);

  const onLangSelectorClick = () => {
    setIsOpened((prevState) => !prevState);
  };

  const onChangeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem(LS_LANG_KEY, lang);
    setIsOpened(false);
  };

  return (
    <div className={styles.root}>
      <div
        className={styles.current}
        onClick={onLangSelectorClick}
        onBlur={() => setIsOpened(false)}
        tabIndex={0}
        role="button"
      >
        <div className={styles.name}>{i18n.language}</div>
        <Icon className={styles.arrow} name="common/arrow" />
      </div>
      <ul hidden={!isOpened} className={styles.dropdown}>
        {localesKeys.map((lang) => (
          <li
            key={lang}
            role="presentation"
            onMouseDown={() => onChangeLanguage(lang)}
            className={clsx(styles.dropdownItem, {
              [styles.currentItem]: lang === i18n.language,
            })}
          >
            {lang}
          </li>
        ))}
      </ul>
    </div>
  );
};
